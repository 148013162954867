.button {
	&.main {
		border: 2px solid #fff;
		color: #fff;
		font-family: "Montserrat", sans-serif;
		text-align: center;
		position: relative;
		max-width: 100%;
		width: 265px;
		height: 70px;
		line-height: 70px;
		transition: all 0.2s;
		display: block;
		margin: 0 auto;
		&.lightBlue {
			background-color: inherit;
			color: $lightBlue;
			border: 2px solid $lightBlue;
			&:hover {
				background-color: $lightBlue;
				border: 2px solid $lightBlue;
				color: #fff;
			}
			&:after {
				background-image: url(../img/more-lightBlue.svg);
				background-repeat: no-repeat;
				width: 21px;
				height: 21px;
			}
			&:hover::after {
				background-image: url(../img/more-white.svg);
				transform: translate(0, -50%);
			}
		}
		&.lightGreen {
			background-color: inherit;
			color: $lightGreen;
			border: 2px solid $lightGreen;
			&:hover {
				background-color: $lightGreen;
				border: 2px solid $lightGreen;
				color: #fff;
			}
			&:after {
				background-image: url(../img/more-lightGreen.svg);
				background-repeat: no-repeat;
				width: 21px;
				height: 21px;
			}
			&:hover::after {
				background-image: url(../img/more-white.svg);
				transform: translate(0, -50%);
			}
		}
		&.gold {
			background-color: inherit;
			color: $gold;
			border: 2px solid $gold;
			&:hover {
				background-color: $gold;
				border: 2px solid $gold;
				color: #fff;
			}
			&:after {
				background-image: url(../img/more-gold.svg);
				background-repeat: no-repeat;
				width: 21px;
				height: 21px;
			}
			&:hover::after {
				background-image: url(../img/more-white.svg);
				transform: translate(0, -50%);
			}
		}
		&.blc {
			border: 2px solid $info;
			background-color: inherit;
			color: $info;
			&:hover {
				background-color: $info;
				border: 2px solid inherit;
				color: #fff;
				&:after {
					background-image: url(../img/button_arrow-w.svg);
				}
			}
			&:after {
				background-image: url(../img/button_blc.svg);
			}
		}
		&.white {
			border: 2px solid #fff;
			background-color: inherit;
			color: #fff;
			&:hover {
				background-color: $info;
				border: 2px solid $info;
				color: #fff;
				&:after {
					background-image: url(../img/button_arrow-w.svg);
				}
			}
			&:after {
				background-image: url(../img/button_arrow-w.svg);
			}
		}
		&:after {
			content: "";
			background-image: url(../img/button_arrow.svg);
			position: absolute;
			right: 2rem;
			top: 50%;
			transform: translateY(-50%);
			width: 17px;
			height: 9px;
			transition: all 0.2s;
		}
		&:hover {
			opacity: 1 !important;
		}
		&:hover:after {
			transform: translate(10px, -50%);
		}
		&.sub {
			border-right: 0;
			border-top: 0;
			border-left: 0;
			text-align: left;
			width: 180px;
			height: 60px;
			line-height: 60px;
			margin: initial;
			&:hover {
				background-color: initial;
				color: inherit;
				&:after {
					background-image: url(../img/button_blc.svg);
				}
			}
		}
		&.sub.white:hover {
			border-top: 0;
			border-right: 0;
			border-left: 0;
			border-bottom: 2px solid #fff;
			&:after {
				background-image: url(../img/button_arrow-w.svg);
			}
		}
	}
	&.contact {
		font-family: "Montserrat", sans-serif;
		text-align: center;
		position: relative;
		max-width: 100%;
		width: 265px;
		height: 70px;
		line-height: 70px;
		transition: all 0.2s;
		display: block;
		margin: 0 auto;
		color: #fff;
		background-color: $primary;
		&:after {
			background-image: none;
		}
		&:hover {
			border: 1px solid $primary;
			background-color: #fff;
			color: $primary;
		}
		&:hover::after {
			background-image: none;
		}
	}
}
