.contact {
	.contact {
		&__nini {
			background-color: #d9d8e1;
			color: $info;
		}
		&__hissu {
			background-color: #2a2e56;
		}
	}
}
