.top {
	.about {
		padding-left: 0;
		padding-right: 0;
		&__main {
			color: #fff;
			text-align: center;
			background-color: $primary;
			padding: 6rem 0;
		}
		.main-heading {
			margin-bottom: 4.5rem;
		}
		&__img img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
		}
		&__banner {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			padding: 4rem;
			background-color: $lightGray;
		}
		@media (max-width: 787px) {
			&__main {
				padding-top: 4rem;
				padding-bottom: 4rem;
			}
		}
		@media (max-width: 576px) {
			&__main {
				padding: 4rem 3rem;
			}
		}
	}
	.service {
		&__box {
			padding: 3rem 6rem;
			color: #fff;
			height: 100%;

			&.one {
				background-color: $lightBlue;
			}
			&.two {
				background-color: $lightGreen;
			}
			&.three {
				background-color: $gold;
			}
		}
		&__btn {
			margin-top: 3rem;
		}
		&__img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
			transition: all 0.4s;
		}
		&__img.zoom {
			transform: scale(1.1);
		}
		@media (max-width: 991px) {
			&__box {
				padding: 3rem;
			}
		}
	}
	.news {
		margin-bottom: 0;
		padding: 5rem 0;
		background-color: $secondary;
		&__wrap {
			padding: 5rem 0;
		}
	}
	.blog {
		background-color: $secondary;
		padding-bottom: 5rem;
		&__img {
			width: 100%;
			object-fit: cover;
			object-position: center;
			margin-bottom: 1rem;
		}
		&__date {
			color: #939393;
			font-size: 0.875rem;
			margin-bottom: 0;
		}
		&__title {
			font-size: 1rem;
			margin-top: 0.5rem;
		}
		.main.button {
			margin-top: 4rem;
		}
	}
	.link {
		&__cover {
			position: relative;
			&:after {
				content: "";
				background-color: rgba($color: #2a2e56, $alpha: 0.15);
				position: absolute;
				width: 100%;
				height: 100%;
				left: 0;
				top: 0;
			}
		}
	}
}
