.works {
	.anchor {
		margin-bottom: 0;
		&__wrap {
			&:hover {
				opacity: 1;
			}
		}
		&__box {
			padding: 3rem 0;
			color: #fff;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 1.55rem;
			height: 100%;
			&.yakei {
				background-color: $lightBlue;
			}
			&.inbound {
				background-color: $lightGreen;
			}
			&.web {
				background-color: $gold;
			}
		}
		&__arrow {
			margin-left: 2rem;
		}
		&__bg {
			transition: all 0.4s;
		}
		&__bg.zoom {
			transform: scale(1.1);
		}
		@media (max-width: 1199px) {
			&__box {
				font-size: 1.2rem;
			}
		}
		@media (max-width: 991px) {
			&__box {
				padding: 2rem 0;
				font-size: 1.1rem;
			}
			&__arrow {
				margin-left: 1rem;
				width: 30px;
			}
		}
	}
	.works {
		& .container {
			position: relative;
			top: -2rem;
			background-color: #fff;
			padding-top: 3rem;
		}
		&__item {
			text-align: center;
			display: block;
		}
		&__item-name {
			font-size: 1.2rem;
			margin-bottom: 1rem;
			font-weight: bold;
			&.yakei {
				color: $lightBlue;
			}
			&.inbound {
				color: $lightGreen;
			}
			&.web {
				color: $gold;
			}
		}
		&__item-img {
			width: 100%;
			margin-bottom: 2rem;
		}
		@media (max-width: 576px) {
			&__item-name {
				font-size: 1rem;
				text-align: left;
			}
			&__item-more {
				width: 70%;
			}
		}
	}
	.modal {
		&__body {
			padding: 2rem;
		}
		&__content {
			display: flex;
			align-items: center;
			img {
				margin-bottom: 3rem;
			}
			h5 {
				margin-bottom: 1rem;
				font-weight: bold;
				&.yakei {
					color: $lightBlue;
				}
				&.inbound {
					color: $lightGreen;
				}
				&.web {
					color: $gold;
				}
			}
			h6 {
				font-weight: bold;
				margin-bottom: 2rem;
				&.yakei {
					color: $lightBlue;
				}
				&.inbound {
					color: $lightGreen;
				}
				&.web {
					color: $gold;
				}
			}
		}
		@media (max-width: 576px) {
			&__body {
				padding: 1rem;
			}
		}
	}
}
