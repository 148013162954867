.hero {
	background-image: url(../img/hero.png);
	background-size: cover;
	background-position: center;
	height: 100vh;
	position: relative;
	margin-bottom: 0 !important;
	&__content {
		position: absolute;
		bottom: 5rem;
		left: 3rem;
		display: flex;
		align-items: center;
		width: 90%;
		z-index: 2;
	}
	&__text {
		font-size: 2rem;
		color: #fff;
		letter-spacing: 0.5rem;
	}
	@media (max-width: 576px) {
		&__content {
			// flex-direction: column;
			// align-items: flex-start;
			align-items: baseline;
			width: 88%;
			left: 1rem;
		}
		&__horizon {
			// display: none;
		}
		&__text {
			font-size: 1.5rem;
		}
	}
}
.hero:after {
	content: "";
	background: linear-gradient(
		to top,
		#080911 0%,
		#191c34 0%,
		#12142a 0%,
		transparent 100%
	);
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 50%;
}

.hero-child {
	height: 80vh;
	position: relative;
	margin-bottom: 0 !important;
	&__img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
	}
	&__content {
		position: absolute;
		width: 90%;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 2;
		color: #fff;
	}

	@media (max-width: 767px) {
		&__content {
			justify-content: flex-start;
		}
	}
}
.hero-child:after {
	content: "";
	background: linear-gradient(
		to top,
		#080911 0%,
		#191c34 0%,
		#12142a 0%,
		transparent 100%
	);
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 50%;
}
