.company {
	.company {
		padding-bottom: 9rem;
	}
	.message {
		position: relative;
		top: -8rem;
		margin-bottom: -5rem;
	}
	.info {
		background-color: $secondary;
		padding-top: 5rem;
		padding-bottom: 5rem;
	}
	.table {
		&__item {
		}
		&__item div:first-child div {
			border-top: 1px solid #2a2e56;
			padding: 2rem 0;
			font-weight: bold;
		}
		&__item div:last-child div {
			border-top: 1px solid #cdd6dd;
			padding: 2rem 0;
		}
		@media (max-width: 991px) {
			&__item div:first-child div {
				padding: 1rem 0;
				padding-bottom: 0;
			}
			&__item div:last-child div {
				border-top: 0;
				padding: 1rem 0;
			}
		}
	}
	.access {
		background-color: $secondary;
		padding-top: 5rem;
		padding-bottom: 5rem;
		margin-bottom: 0;
		&__map {
			height: 0;
			overflow: hidden;
			padding-bottom: 50%;
			position: relative;
			iframe {
				position: absolute;
				left: 0;
				top: 0;
				height: 100%;
				width: 100%;
			}
		}
	}
}
