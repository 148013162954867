.articles {
	&__title {
		font-size: 2rem;
	}
	&__content {
		margin: 3rem auto;
		h2 {
			font-size: 1.5rem;
			padding-bottom: 0.5rem;
			border-bottom: 1px solid $borderColor;
			margin-bottom: 1rem;
			margin-top: 3rem;
		}
	}
	@media (max-width: 991px) {
		margin-bottom: 5rem;
		&__title {
			font-size: 1.5rem;
		}
		&__content {
			h2 {
				font-size: 1.2rem;
			}
		}
	}
}

.sidearea {
	&__wrap {
		margin-bottom: 3rem;
	}
	&__title {
		display: flex;
		flex-direction: column-reverse;
		&-jp {
			font-size: 0.875rem;
			margin-bottom: 1.5rem;
		}
		&-en {
			font-size: 2rem;
			font-family: "Raleway", sans-serif;
		}
	}
	&__desc {
		font-size: 0.875rem;
	}
	&__category {
		padding: 0;
		margin: 0;
		margin-bottom: 5rem;
		list-style: none;
	}
	&__articles {
		padding: 0;
		margin: 0;
		margin-bottom: 5rem;
		list-style: none;
	}
	&__articles li {
		padding-top: 1rem;
		padding-bottom: 1rem;
		border-bottom: 1px solid $borderColor;
	}
	&__date {
		display: block;
		margin-bottom: 0.5rem;
		font-size: 0.875rem;
	}
}
