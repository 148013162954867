@import "~jquery-drawer/scss/drawer.scss";
$minWidth: 1200px;
$maxWidth: 1199px;
$drawerHeight: 55px;
$menuItemWidth: 140px;
$menuHoverWidth: 200px;

.drawers .drawer {
	&-hamburger-icon,
	&-hamburger-icon:before,
	&-hamburger-icon:after {
		background-color: #fff;
	}
	&__bar {
		background-color: transparent;
		position: fixed;
		width: 100%;
		height: $drawerHeight;
		top: 0;
		z-index: 3;
		&.bgShow {
			background-color: $primary;
		}
	}
	&__main-logo {
		position: fixed;
		top: 7px;
		left: 3rem;
		z-index: 4;
	}
	&__lang {
		display: flex;
		align-items: center;
		position: relative;
	}
	@media (min-width: $minWidth) {
		&__drawer-logo {
			display: none;
		}
		&-hamburger {
			display: none;
		}
		&-nav {
			position: fixed;
			z-index: 3;
			right: 0;
			top: 0;
			overflow: visible;
			width: 100%;
			height: $drawerHeight;
			background-color: transparent;
		}
		&-menu {
			display: flex;
			justify-content: flex-end;
		}
		&-menu-item {
			color: #fff;
		}
		&__hover {
			position: relative;
		}
		&__hover-child {
			display: none;
			padding-left: 0;
			text-align: center;
			list-style: none;
			position: absolute;
			padding-top: 0.5rem;
			padding-bottom: 0.5rem;
			top: 3rem;
			left: 50%;
			transform: translateX(-50%);
			background-color: #fff;
			color: $info;
			box-shadow: 0 0.5rem 1rem rgb(34 34 34 / 15%);
			&.show {
				display: block;
			}
		}
		&__hover-item {
			text-align: center;
			padding: 0.5rem 0;
			display: block;
			width: $menuHoverWidth;
		}
		&-menu-item {
			width: $menuItemWidth;
			text-align: center;
		}
		&__lang span {
			color: #fff;
		}
		&__lang:before {
			content: "";
			background-image: url(../img/lang.svg);
			width: 15px;
			height: 15px;
			position: absolute;
			left: 10px;
		}
		&__sp {
			display: none;
		}
	}
	@media (max-width: $maxWidth) {
		&__main-logo {
			left: 2rem;
		}
		&-nav {
			padding: 1rem;
			overflow: auto;
		}
		&__drawer-logo {
			margin-top: 1rem;
		}
		&-menu {
			padding-top: 1rem;
		}
		&-menu li {
			border-bottom: 1px solid $borderColor;
		}
		&-menu li:last-of-type {
			border: none;
		}
		&__label {
			font-family: "Raleway", sans-serif;
			font-size: 0.5rem;
		}
		&__hover-none {
			pointer-events: none;
		}
		&__hover-child {
			list-style: none;
		}
		&__hover-child li {
			border: 0;
			padding: 0.5rem 0;
		}
		&__lang a {
			font-size: 0.875rem;
		}
	}
}

// Open Drawer Nav
.drawer {
	&-open &-hamburger-icon {
		background-color: transparent;
	}
	&-open &-hamburger-icon:before,
	&-open &-hamburger-icon:after {
		background-color: $info;
	}
	&--right.drawer-open &-hamburger {
		right: 0;
	}
}
