.service {
	.service {
		&__tri {
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			top: -10px;
			z-index: 1;
		}
		&__box {
			padding: 3rem 6rem;
			color: #fff;
			height: 100%;

			&.one {
				background-color: $lightBlue;
			}
			&.two {
				background-color: $lightGreen;
			}
			&.three {
				background-color: $gold;
			}
		}
		&__btn {
			margin-top: 3rem;
		}
		&__img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
			transition: all 0.4s;
		}
		&__img.zoom {
			transform: scale(1.1);
		}
		@media (max-width: 991px) {
			&__box {
				padding: 3rem 2rem;
			}
		}
	}

	&__content .container {
		padding-top: 2rem;
		padding-bottom: 2rem;
		position: relative;
		top: -4rem;
		margin-bottom: -6rem;
		background-color: #fff;
		@media (max-width: 576px) {
			// padding: 0;
		}
	}
}
