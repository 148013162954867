//Common
html {
	scroll-behavior: smooth;
	@media (max-width: 576px) {
		font-size: 14px;
	}
}
body {
	color: $info;
	font-family: YuGothic, "Yu Gothic Medium", "Yu Gothic",
		"Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", メイリオ, sans-serif;
	letter-spacing: 0.1rem;
	line-height: 1.8;
}
img {
	max-width: 100%;
	height: auto;
}
a {
	color: inherit;
	text-decoration: none !important;
}
a:hover {
	text-decoration: none !important;
	color: inherit;
	opacity: 0.8;
}

button {
	outline: none;
	border: none;
	&:focus {
		outline: none;
	}
	&:hover {
		opacity: 0.8;
	}
}
:focus {
	outline: none;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
	-webkit-appearance: none !important;
	margin: 0;
}

input[type="number"] {
	-moz-appearance: textfield !important;
}
h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
	line-height: initial;
}
.fwb {
	font-weight: bold;
}
.fwn {
	font-weight: normal;
}
.serif {
	font-family: "Noto Serif JP", serif;
}
.sans {
	font-family: "Noto Sans JP", sans-serif !important;
}
.ra {
	font-family: "Raleway", sans-serif;
}
a#yakei,
a#inbound,
a#web {
	display: block;
	padding-top: 100px;
	margin-top: -100px;
}

section {
	margin-bottom: 6rem;

	&:last-of-type {
		margin-bottom: 3rem;
	}
	&.cta {
		margin-bottom: 0 !important;
	}
	@media (max-width: 768px) {
		margin-bottom: 3rem;
		&:last-of-type {
			margin-bottom: 1.5rem;
		}
	}
}

// MainHeading
.main-heading {
	display: inline-flex;
	justify-content: center;
	flex-direction: column;
	position: relative;
	h1 {
		font-size: 2.4rem;
		letter-spacing: 0.5rem;
	}
	h2 {
		font-size: 1rem;
		font-family: "Raleway", sans-serif;
		margin-bottom: 1rem;
	}
	@media (max-width: 991px) {
		h1 {
			font-size: 2rem;
		}
	}
}

.sub-heading {
	h1 {
		font-size: 2rem;
	}
	h2 {
		font-size: 1rem;
		font-family: "Raleway", sans-serif;
	}
}

//Main
main {
	margin-top: 4rem;
	margin-bottom: 8rem;
	@media (max-width: 576px) {
		margin-top: 3rem;
	}
}

// Page Header
.page-header {
	color: #fff;
	padding-top: 5rem;
	padding-bottom: 5rem;
	margin-bottom: 0;
	background-color: $primary;
	&.yakei {
		background-color: $lightBlue;
	}
	&.inbound {
		background-color: $lightGreen;
	}
	&.web {
		background-color: $gold;
	}
	@media (max-width: 576px) {
		padding-top: 3rem;
		padding-bottom: 3rem;
	}
}

//Article
.article {
	&-wrap {
		margin: 0;
		padding: 0;
		list-style: none;
	}
	&-item {
		border-bottom: 1px solid $borderColor;
		padding-bottom: 1rem;
		margin-bottom: 1rem;
		&:last-of-type {
			margin-bottom: 0;
		}
	}
	&-info {
		display: flex;
		align-items: center;
	}
	&-date {
		margin-right: 1rem;
		color: #939393;
	}
	&-category {
		display: inline-block;
		padding: 0.35em 0.65em;
		font-size: 0.75em;
		font-weight: 700;
		line-height: 1;
		color: #fff;
		text-align: center;
		white-space: nowrap;
		vertical-align: baseline;
		border-radius: 0.25rem;
		background-color: $primary;
	}
}

//Pagination
.paginations {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 40px 0;
	position: relative;
	font-size: 1.2rem;
	& span,
	& a {
		display: block;
		width: auto;
		margin: 4px;
		padding: 8px;
		text-decoration: none;
		text-align: center;
		line-height: 16px;
	}
	& .pager {
		width: 32px;
	}
	& a:hover,
	& .current {
		color: $primary;
	}
	& a.prev {
		margin-right: 16px;
		background-color: $primary;
		color: #fff;
		border: 1px solid $primary;
		border-radius: 50%;
	}
	& a.next {
		margin-left: 16px;
		background-color: $primary;
		color: #fff;
		border: 1px solid $primary;
		border-radius: 50%;
	}
}

// FooterArea
.cta {
	padding-top: 5rem;
	padding-bottom: 3rem;
	background-size: cover;
	background-position: center;
	&.ctaCorp {
		background-image: url(../../assets/img/cta_bg.jpg);
		// background-image: url(../../assets/img/cta-bg.png);
	}
	&.ctaRecruit {
		// background-image: url(../../assets/img/cta-rec_bg.png);
		background-image: url(../../assets/img/cta-rec-bg.png);
	}
	.container {
		position: relative;
		z-index: 2;
	}
	&:after {
		position: absolute;
		left: 0;
		top: 0;
		display: block;
		width: 100%;
		height: 100%;
		content: "";
		background-color: #fff;
		opacity: 0.4;
	}
	&.ctaCorp:after {
		position: absolute;
		left: 0;
		top: 0;
		display: block;
		width: 100%;
		height: 100%;
		content: "";
		background-color: #eff5ed;
		opacity: 0.1;
	}
	.tel {
		color: $primary;
		font-weight: 500;
		letter-spacing: 0.2rem;
	}
	.time {
		padding-bottom: 0;
		text-align: center;
	}
	.column {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	i {
		font-size: 1.5rem;
	}
}

//Footer
footer {
	color: #fff;
	background-color: #333;
	font-size: 0.875rem;
	ul {
		padding-left: 0;
		li {
			margin: 0.75rem auto;
		}
	}
}

#page-top {
	position: fixed;
	width: 48px;
	right: 1rem;
	bottom: 1rem;
	z-index: 7;
	//   &.show {
	//     opacity: 1;
	//   }
	// }
}
