$enable-responsive-font-sizes: true;

$primary: #2a2e56;
$secondary: #f5f5f5;

$info: #333333;

$borderColor: #dee2e6;

$lightBlue: #394989;
$lightGray: #d9d8e1;
$lightGreen: #4ea0ae;
$gold: #ccae95;
$black: #222;

.text-lightBlue {
	color: $lightBlue;
}
.text-lightGreen {
	color: $lightGreen;
}
.text-gold {
	color: $gold;
}
.bg-lightBlue {
	background-color: $lightBlue;
}
.bg-lightGreen {
	background-color: $lightGreen;
}
.bg-gold {
	background-color: $gold;
}

@import "~bootstrap/scss/bootstrap.scss";
