.cta {
	margin-bottom: 0;
	background-image: url(../img/cta_bg.png);
	background-position: center;
	position: relative;
	padding: 5rem 0;
	color: #fff;
	&:after {
		content: "";
		background-color: rgba($color: #2a2e56, $alpha: 0.9);
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
	}
}

.footer {
	background-color: #222;
	color: #fff;
	margin-bottom: 0 !important;
	&__menu {
		list-style: none;
		padding-left: 0;
	}
	&__menu li {
		margin: 1rem 0;
	}
}
