@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300&display=swap");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css");

// Plugin
@import "scss/bootstrap.scss";
@import "scss/drawer.scss";
@import "scss/swiper.scss";
@import "scss/scrollhint.scss";

// Styles]
@import "scss/breadcrumb.scss";
@import "scss/buttons.scss";
@import "scss/common.scss";
@import "scss/headerArea.scss";
@import "scss/top.scss";
@import "scss/footerArea.scss";
@import "scss/articles.scss";
@import "scss/service.scss";
@import "scss/faq.scss";
@import "scss/company.scss";
@import "scss/works.scss";
@import "scss/contact.scss";
