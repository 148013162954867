.breadcrumb {
	margin: 0;
	padding: 0 1rem;
	list-style: none;
	position: absolute;
	right: 0;
	bottom: 1rem;
	background-color: inherit;
	color: #fff;
	font-size: 0.875rem;
	z-index: 2;
	&__wrap {
		position: absolute;
		right: 0;
		bottom: 4rem;
	}
	& li {
		display: inline;
		list-style: none;
		&:after {
			padding: 0 0.8em;
			color: #fff;
			content: "›";
			transition: all 0.1s;
			font-size: 1.3rem;
			margin-top: -0.1rem;
		}
		&:last-child:after {
			content: "";
		}
	}
	@media (max-width: 576px) {
		font-size: 0.875rem;
		line-height: 1.4rem;
	}
}
