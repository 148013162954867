.faq {
	.anchor {
		position: relative;
		top: -43px;
		margin-bottom: 21px;
		&__box {
			color: #fff;
			transition: all 0.2s;
			display: block;
			text-align: center;
			font-size: 1.2rem;
			&:hover {
				opacity: 1;
				transform: translateY(5px);
			}
			img {
				width: 20px;
				height: auto;
				margin-left: 1rem;
			}
		}
	}
	.accordion {
		&-button {
			background-color: #fff;
			box-shadow: none;
			position: relative;
			padding-left: 2rem;
			&:active,
			&:focus {
				z-index: initial;
				border: none;
				// font-weight: bold;
			}
			&:hover {
				opacity: 1;
				cursor: pointer;
			}
		}
		&-button:before {
			content: "Q.　";
			position: absolute;
			left: 0;
			top: 1rem;
		}
		&-item {
			margin-bottom: 1rem;
		}
		&-body {
			position: relative;
			padding-left: 2rem;
		}
		&-body:before {
			content: "A.　";
			position: absolute;
			left: 0;
			top: 1rem;
		}
	}
}
